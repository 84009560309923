import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyAAdCk4JYRiZydPGY744CcfTDx0ItbROq8",
  authDomain: "kaeus-web.firebaseapp.com",
  projectId: "kaeus-web",
  storageBucket: "kaeus-web.appspot.com",
  messagingSenderId: "318142285256",
  appId: "1:318142285256:web:1b6ffbba36c623e63f6cfd",
  measurementId: "G-2DNFDV0V8D"
};

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);

// eslint-disable-next-line no-restricted-globals
if (location.host === "localhost" || location.host === "127.0.0.1"){
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}

export const contactUs = httpsCallable(functions, 'contactUs');
