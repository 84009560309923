import './App.css';
import React, { Suspense } from 'react';
import {
  RouterProvider,
} from "react-router-dom";
import router from "./pages";
// import Loading from './pages/Loading';  

function App() {
  return (
    <Suspense>
    <RouterProvider router={router()}/>
    
     {/* <Loading/> */}
    </Suspense>
  );
}

export default App;
