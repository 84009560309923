import { createBrowserRouter } from "react-router-dom";
// import CommingSoon from "./CommingSoon";
import NavMainFooter from "../layouts/NavMainFooter";
import WelcomePage from "./WelcomePage";

export default function router() {
  return createBrowserRouter([
    {
      path: "/*",
      element: <NavMainFooter main={<WelcomePage />} />,
    },
  ]);
}
