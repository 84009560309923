import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./Services.css";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import services_data_analyze from "../assets/services_data_analyze.png"
import services_data_gather from "../assets/services_data_gather.png"
import services_data_manage from "../assets/services_data_manage.png"
import services_data_front from "../assets/services_data_front.png"
import services_architech from "../assets/services_architech.png"
import services_dataops from "../assets/services_dataops.png"



export default function Services() {
  const { t } = useTranslation();

  let i=1;

  const service_section = (image_src, title, text) => {
    i = (i+1) % 2;
    return (
      <Col>
      <Row xs={1} md={2}>
        <div className={`services-image align-self-top order-1 order-md-${1+i} order-xl-1`}>
          <Image src={image_src} fluid/>
        </div>
        <div className="services-description order-2 order-md-1 order-xl-2 align-self-top mt-3 mt-md-0">
            <h5 className="text-left">{title}</h5>
            <p className="text-left">
              {t(text)}
            </p>
        </div>
      </Row>
    </Col>    
    )}

  return (
    <>  
      <Container
        className="clearfix text-left my-5"
      >
        <Row xs={1} xl={2} className="g-4 justify-content-evenly">
          { service_section(services_data_gather, <>data<strong>Gather</strong> | data<strong>Curate</strong></>, "service_1_text")}
          { service_section(services_data_analyze, <>data<strong>Analize</strong> | data<strong>Learn</strong></>, "service_2_text")}
          { service_section(services_data_front, <>data<strong>View</strong> | data<strong>Serve</strong></>, "service_3_text")}
          { service_section(services_data_manage, <>data<strong>Manage</strong> | data<strong>Model</strong></>, "service_4_text")}
          { service_section(services_dataops, <>dev<strong>Ops</strong> | data<strong>Ops</strong> | ML<strong>Ops</strong></>, "service_6_text")}
          { service_section(services_architech, <>archi<strong>Tech</strong> | system<strong>Design</strong></>, "service_5_text")}
        </Row>
      </Container>
    </>
  );
}
