import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./ContactUs.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {
  RiMap2Line,
  RiPhoneLine,
  RiMailSendLine,
  RiWhatsappLine,
} from "react-icons/ri";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from 'formik';
import Modal from 'react-bootstrap/Modal';

import { contactUs } from "../firebase";

export default function ContactUs() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    onSubmit: (values, actions) => {
      contactUs(values).then((result) => {
        console.log(result);
        actions.resetForm({values: {
          name: '',
          email: '',
          message: '',
        }})
        setShow(true);
      }).catch((error) => {
        console.log(error);
      }); 
    },
  });

  return (
    <>
      <Modal show={show} onHide={handleClose} dismissible="true" variant="light" bg="light" className="contactus-alert">
        <Modal.Header closeButton="true" closeVariant="white">{t('message_received')}</Modal.Header>
      </Modal>
      <Container className="contactus p-3">
        <Row>
          <Col className="col-12">
            <h4>{t("Contact Us")}</h4>
          </Col>
        </Row>

        <Row>
          <Col className="col-12 col-md-6 align-self-center flex px-lg-4 px-xl-5 px-sm-3 mb-5">
            <Row xs={2} className="g-0 my-4">
              <Col className="contactus-icon"><RiMap2Line size="1.3em" className="h-100" /></Col>
              <Col className="w-75 h-100"><p className="align-middle h-100 d-inline">{t("office_address")}</p></Col>
            </Row>
            <Row xs={3} className="g-0 my-4">
              <Col className="contactus-icon"><RiPhoneLine size="1.3em" className="h-100" /></Col>
              <Col className="w-auto h-100 pe-3"><p className="align-middle h-100 d-inline">{t("office_phone")}</p></Col>
              <Col><a href="https://wa.me/message/INJVOS752CZII1"><RiWhatsappLine size="1.3em" className="align-middle h-100" /></a> </Col>
            </Row>
            <Row xs={2} className="g-0 mt-4 mb-1 mb-md-4">
              <Col className="contactus-icon"><RiMailSendLine size="1.3em" className="h-100" /></Col>
              <Col className="w-75 h-100"><p className="align-middle h-100 d-inline">{t("office_email")}</p></Col>
            </Row>
            <div className="p-5 m-5 d-none d-md-block"></div>
          </Col>
          <Col className="col-12 col-md-6 px-sm-3 px-lg-4 px-xl-5">
            <p>{t("send_message")}</p>
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>{t("form_name")}</Form.Label>
                <Form.Control type="text" className="contactus-control" {...formik.getFieldProps('name')}/>
              </Form.Group>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>{t("form_email")}</Form.Label>
                <Form.Control type="email" className="contactus-control" {...formik.getFieldProps('email')}/>
              </Form.Group>
              <Form.Group className="mb-5" controlId="message">
                <Form.Label>{t("form_message")}</Form.Label>
                <Form.Control as="textarea" rows={4} className="contactus-control" {...formik.getFieldProps('message')}/>
              </Form.Group>
              <Form.Group className="mb-1" controlId="formSumbit">
                <Button
                  variant="primary"
                  type="submit"
                  className="contactus-button"
                >
                  {t("Send")}
                </Button>
              </Form.Group>
              <Form.Text className="text-muted">
                {t("privacy_policy")}
              </Form.Text>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}
