import MainBanner from "../components/MainBanner";
import AboutUs from "../components/AboutUs";
import Services from "../components/Services";
import ContactUs from "../components/ContactUs";
import "./WelcomePage.css";

export default function WelcomePage() {
  return (
    <>
      <div className="welcomepage-section">
      <div class="anchor align-bottom">
            <a id="home" href="home" className="align-self-bottom d-inline">X</a>
        </div>
                <MainBanner />
      </div>
      <div className="welcomepage-section">
        <div class="anchor align-bottom">
            <a id="aboutus" href="aboutus" className="align-self-bottom d-inline">X</a>
        </div>
        <AboutUs />
      </div>
      <div className="welcomepage-section">
      <div class="anchor align-bottom">
            <a ID="services" href="services" className="align-self-bottom d-inline">X</a>
        </div>
        <Services />
      </div>
      <div className="welcomepage-section">
      <div class="anchor align-bottom">
            <a id="contactus" href="contactus" className="align-self-bottom d-inline">X</a>
        </div>
        <ContactUs />
      </div>
    </>
  );
}
