import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./MainBanner.css";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";

import {
  RiGlobalLine,
  RiBubbleChartLine,
  RiServiceLine,
} from "react-icons/ri";

export default function MainBanner() {
  const { t } = useTranslation();

  return (
    <>
      <Container
        className="clearfix text-center p-5"
        flex
      >
        <Row xs={1} md={2} lg={3} className="g-4 justify-content-evenly">
          <Col>
            <Card variant="light" bg="light" className="float-start px-xl-5">
              <Card.Title>
                <RiGlobalLine size="3em" />
              </Card.Title>
              <Card.Text>{t("about_us_text")}</Card.Text>
            </Card>
          </Col>
          <Col>
            <Card variant="light" bg="light" className="float-start px-xl-5">
              <Card.Title>
                <RiBubbleChartLine size="3em" />
              </Card.Title>
              <Card.Text>{t("what_we_do_text")}</Card.Text>
            </Card>
          </Col>
          <Col>
            <Card variant="light" bg="light" className="float-start px-xl-5">
              <Card.Title>
                <RiServiceLine size="3em" />
              </Card.Title>
              <Card.Text>{t("why_us_text")}</Card.Text>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
